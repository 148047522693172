
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./BlogMainPost.module.css";

const BlogMainPost = ({ post }) => {
  const image = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  };
  const category = post?.categories?.nodes[0];
  const author = post.author.node;

  return (
    <>
      <article className={`${s.postItem} row align-items-center`}>
        {image?.data && (
          <div className="col-md-6 col-xl-5">
            <Link to={post.uri} >
              <GatsbyImage
                loading="eager"
                placeholder="none"
                image={image.data}
                alt={image.alt}
                className={`w-100 ${s.postImg}`}
              />
            </Link>
          </div>
        )}
        <div className="col-md-6 col-xl-7">
          <div className={s.postData}>
            <Link to={post.uri} className="text-decoration-none"><h3>{parse(post.title)}</h3></Link>
            <div className={`${s.postDate}`}>
              <span className={`me-2 pe-1`}>
                {post.date}
              </span>
              by
              <Link to={author.uri} className={`${s.postAuthorName}`}>
                {author.name}
              </Link>
            </div>
            {!!post?.excerpt && <div className={s.excerpt}>{parse(post?.excerpt)}</div>}
            {!!category && <div className="d-flex align-items-center">
              <Link to={category?.uri} className={s.category}>{category?.name}</Link>
            </div>}
          </div>
        </div>
      </article>
    </>
  );
};

export default BlogMainPost;
