import { graphql } from "gatsby"
import React from "react"

import * as s from "../assets/css/blog.module.css"
import Seo from "../components/seo"

import BlogCategories from './../components/REDESIGN/BlogCategories/BlogCategories'
import BlogMainPost from './../components/REDESIGN/BlogMainPost/BlogMainPost'
import BlogPost from './../components/REDESIGN/BlogPost/BlogPost'
import Footer from './../components/REDESIGN/footer/footer'
import Header from './../components/REDESIGN/header/header'
import NewsletterSubscribeFooterBannerNew from './../components/REDESIGN/NewsletterSubscribeFooterBannerNew/NewsletterSubscribeFooterBannerNew'
import Pagination from './../components/REDESIGN/Pagination/Pagination'
import schemaBuilder from './../utils/schemaBuilder'

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, currentPage, pages },
  location
}) => {

  const posts = [...data?.allWpPost?.nodes];

  const categories = [...data?.categories?.nodes];

  const page = data?.page;
  page.uri = '/blog/';

  if (currentPage > 1) {
    if (!!page.seo.metaDesc) {
      if (!page.seo.metaDesc.includes(` - Page ${currentPage}`)) {
        page.seo.metaDesc = page.seo.metaDesc + ` - Page ${currentPage}`;
      }
    }
    if (!!page.seo.opengraphDescription) {
      if (!page.seo.opengraphDescription.includes(` - Page ${currentPage}`)) {
        page.seo.opengraphDescription = page.seo.opengraphDescription + ` - Page ${currentPage}`;
      }
    }
    if (!!page.seo.twitterDescription) {
      if (!page.seo.twitterDescription.includes(` - Page ${currentPage}`)) {
        page.seo.twitterDescription = page.seo.twitterDescription + ` - Page ${currentPage}`;
      }
    }
  }

  const site = data?.site;
  const schema = schemaBuilder({ posts: posts, page: page, currentPage: currentPage }, 'blog', site);

  let title = "Blog";
  if (currentPage > 1) {
    title += ` - Page ${currentPage}`;
    page.uri += `${currentPage}/`;
    page.seo.title = page.seo.title.replace(' - ', ` - Page ${currentPage} - `);
  }

  return (
    <>
      <Header />
      <Seo title={title} location={location} pageSeo={page} schema={schema} />

      <div className="pb-5">
        {(!!page?.blogFeaturedPosts?.mainFeaturedPost || page?.blogFeaturedPosts?.otherFeaturedPosts?.length > 0)
          ? <>
            {!!page?.blogFeaturedPosts?.mainFeaturedPost &&
              <div className="container py-5">
                <div className="row" >
                  <div className="col-12">
                    <BlogMainPost post={page?.blogFeaturedPosts?.mainFeaturedPost} />
                  </div>
                </div>
              </div>
            }
            {page?.blogFeaturedPosts?.otherFeaturedPosts?.length > 0 && <>
              <div className="container py-5">
                <div className="row" style={{ rowGap: '32px' }}>
                  <div className="col-12 d-flex flex-xl-column justify-content-between align-items-start mb-4 mb-xl-0">
                    <h2 className={s.heading}>Featured</h2>
                  </div>
                  {page?.blogFeaturedPosts?.otherFeaturedPosts?.map((post) => {
                    return (
                      <div className="col-12 col-md-6 col-xl-4" key={post.uri}>
                        <BlogPost post={post} />
                      </div>
                    )
                  })}
                </div>
              </div>
            </>}
          </>
          : <div className="pt-5"></div>}
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex flex-xl-column justify-content-between align-items-start mb-4 mb-xl-0">
              <h1 className={s.heading}>All Articles</h1>
              <BlogCategories />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row" style={{ rowGap: '32px' }}>
                {posts.length > 0 && posts.map((post, index) => {
                  return (
                    <div className="col-12 col-md-6 col-xl-4" key={post.uri}>
                      <BlogPost post={post} />
                    </div>
                  )
                })}
                {!posts.length &&
                  <div className="col-12">
                    No blog posts found. Add posts to your WordPress site and they'll
                    appear here!
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Pagination pages={pages} currentPage={currentPage} previousPagePath={previousPagePath} nextPagePath={nextPagePath} />
        </div>

      </div>
      <NewsletterSubscribeFooterBannerNew pageTitle={`Blog page`} />
      <Footer />
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    categories: allWpCategory(filter: {count: {gt: 0}}) {
      nodes {
        slug
        name
        uri
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        id
        title
        uri
        date(formatString: "MMM DD, YYYY")
        author {
          node {
            name
            uri
          }
        }
        categories {
          nodes {
            uri
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
      }
    }
    page: wpPage(slug: {eq: "blog"}) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
      blogFeaturedPosts {
        mainFeaturedPost {
          ... on WpPost {
            id
            title
            uri
            date(formatString: "MMM DD, YYYY")
            author {
              node {
                name
                uri
              }
            }
            categories {
              nodes {
                uri
                name
              }
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
            excerpt
          }
        }
        otherFeaturedPosts {
          ... on WpPost {
            id
            title
            uri
            date(formatString: "MMM DD, YYYY")
            author {
              node {
                name
                uri
              }
            }
            categories {
              nodes {
                uri
                name
              }
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
  }
`
