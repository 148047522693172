

import { graphql, Link, useStaticQuery } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from "react";
import * as s from "./BlogCategories.module.css";
import BlogCategoriesMobile from "./BlogCategoriesMobile";

const BlogCategories = ({ }) => {

  const bp = useBreakpoint();


  const {
    wpMenu: { wpMenuItems },
  } = useStaticQuery(graphql`
        query CategoryMenuQuery {
          wpMenu(locations: {eq: CATEGORIES_MENU}) {
            wpMenuItems: menuItems {
              nodes {
                id
                uri
                url
                title
                target
                label
                linkRelationship
                cssClasses
                parentId
                menuItemsSettings {
                  menuItemSettings {
                    megaMenu
                    sideMenu
                    showLastInterview
                    showLastPost
                    showLastPodcast
                  }
                }
              }
            }
          }
        }
      `)

  const flatListToHierarchical = (
    data = [],
    { idKey = "id", parentKey = "parentId", childrenKey = "items" } = {}
  ) => {
    const tree = []
    const childrenOf = {}
    data.forEach(item => {
      const newItem = { ...item }
      const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
      childrenOf[id] = childrenOf[id] || []
      newItem[childrenKey] = childrenOf[id]
      parentId
        ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
        : tree.push(newItem)
    })
    return tree
  }

  let wpMenuItemsTree = flatListToHierarchical(wpMenuItems.nodes);


  return (
    <div className={s.block}>
      {(bp.xl)
        ? <div className={s.menuWrapper}>
          {wpMenuItemsTree.map((mi, i) => {
            return <TopItem item={mi} key={i} />
          })}
        </div>
        : <>
          <BlogCategoriesMobile menuItems={wpMenuItemsTree} />
        </>}
    </div>
  );
};

export default BlogCategories;


const TopItem = ({ item }) => {

  if (!!item.items && item.items.length > 0) {
    return <Submenu item={item} />
  } else {
    return <Link to={item.uri} target={item?.target} className={s.topItem}>{item.label}</Link>
  }
}


const Submenu = ({ item }) => {
  return <div className={`${s.submenuWrapper}`}>
    <span className={s.topItem}>
      {item.label}
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="0 0 12 6" fill="none">
        <path d="M0.964844 1.50078L5.20056 5.1314C5.64995 5.51659 6.31307 5.51659 6.76246 5.1314L10.9982 1.50078" stroke="#063B6D" strokeWidth="1.1" strokeLinecap="round" />
      </svg>
    </span>
    <div className={s.submenu}>
      {item?.items?.map((smi, j) => {
        return <Link key={j} to={smi.uri} target={smi?.target} className={s.submenuItem}>{smi.label}</Link>
      })}
    </div>
  </div>
}