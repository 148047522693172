import { Link, navigate } from "gatsby";
import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import * as s from "./BlogCategoriesMobile.module.css";

var styles = {
  // bmBurgerButton: {
  //   position: "absolute",
  //   width: "30px",
  //   height: "24px",
  //   // right: "36px",
  //   // top: "28px",
  // },
  bmBurgerButton: {
    // display: 'flex',
    display: 'none',
    justifyContent: 'center',
    width: '12px',
    height: '24px',
    position: 'relative',
  },
  bmBurgerBars: {
    background: "#000",
    height: "3px",
    width: "3px",
  },
  bmBurgerBarsHover: {
    background: "#ffffff",
  },
  bmCrossButton: {
    display: 'none',
    top: "20px",
    right: "20px",
    height: "28px",
    width: "28px",
  },
  bmCross: {
    height: "28px",
    background: "#000",
  },
  bmMenuWrap: {
    position: "fixed",
    top: "0",
    height: "100%",
    top: '92px',
    width: '310px',
  },
  bmMenu: {
    background: "#FFFFFF",
    padding: "32px 24px",
    // fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0",
  },
  bmItem: {
    display: "flex",
    flexDirection: "columns",
    color: "#063B6D",
  },
  bmOverlay: {
    top: "0",
    background: "rgba(0, 0, 0, 0.3)",
  },
};

const BlogCategoriesMobile = ({ menuItems }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [opened, setOpened] = useState([]);
  const [searchActive, setSearchActive] = useState(false);

  const openMenu = () => {
    setIsMenuOpen(true);
  }

  const closeMenu = () => {
    setIsMenuOpen(false);
  }

  const toggleSubMenu = i => {
    let newOpened;
    if (isOpened(i)) {
      newOpened = opened.filter(o => o !== i);
    } else {
      newOpened = [...opened, i];
    }
    // console.log(newOpened);
    setOpened(newOpened);
  };

  const isOpened = i => {
    return opened.some(o => o === i);
  };


  const handleOnClose = () => {
    closeMenu();
  }

  const [searchValue, setSearchValue] = React.useState("");

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      navigate(`/search?s=${searchValue}`);
    }
  };

  return (
    <>
      <button onClick={openMenu} className={s.openTrigger}>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
          <path d="M1 3C1 1.89543 1.89543 1 3 1H19C20.1046 1 21 1.89543 21 3V3.81751C21 4.57739 20.7116 5.30895 20.1932 5.86447L14.5379 11.9237C14.1922 12.294 14 12.7817 14 13.2883V17.382C14 17.7607 13.786 18.107 13.4472 18.2764L9.44721 20.2764C8.78231 20.6088 8 20.1253 8 19.382V13.2883C8 12.7817 7.80776 12.294 7.46211 11.9237L1.80683 5.86446C1.28836 5.30895 1 4.57739 1 3.81751V3Z" stroke="#063B6D" strokeWidth="1.5" />
        </svg>
      </button>
      <Menu className="mobileNav" right onClose={handleOnClose} isOpen={isMenuOpen} styles={styles}>
        <div className={`d-flex flex-column pb-4`}>
          <div className="d-flex justify-content-between">
            <div className={s.title}>Categories</div>
            <button onClick={closeMenu} className={s.closeTrigger}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18" stroke="#063B6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 6L18 18" stroke="#063B6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
          <div className={s.menusWrapper}>
            {menuItems.map((mi, i) => {
              return <TopItem item={mi} key={i} />
            })}
          </div>

        </div>
      </Menu>
    </>
  );
};

export default BlogCategoriesMobile;


const TopItem = ({ item }) => {

  if (!!item.items && item.items.length > 0) {
    return <Submenu item={item} />
  } else {
    return <Link to={item.uri} target={item?.target} className={s.topItem}>{item.label}</Link>
  }
}

const Submenu = ({ item }) => {

  const [open, setOpen] = React.useState(false)

  return <div className={`${s.submenuWrapper} ${open ? s.open : ''}`}>
    <span className={s.topItem} onClick={() => { setOpen(!open) }}>
      {item.label}
      <div className="d-flex align-items-center justify-content-center" style={{ width: '16px', aspectRatio: '1/1' }}>
        {!open
          ? <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
            <path d="M1.00078 11.0352L4.6314 6.79944C5.01659 6.35005 5.01658 5.68693 4.6314 5.23754L1.00078 1.00182" stroke="#063B6D" strokeWidth="1.1" strokeLinecap="round" />
          </svg>
          :
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="0 0 12 6" fill="none">
            <path d="M0.964844 1.50078L5.20056 5.1314C5.64995 5.51659 6.31307 5.51659 6.76246 5.1314L10.9982 1.50078" stroke="#063B6D" strokeWidth="1.1" strokeLinecap="round" />
          </svg>
        }
      </div>
    </span>
    <div className={s.overlay} onClick={() => { setOpen(false) }}></div>
    <div className={s.submenu}>
      {item?.items?.map((smi, j) => {
        return <Link key={j} to={smi.uri} target={smi?.target} className={s.submenuItem}>{smi.label}</Link>
      })}
    </div>
  </div>
}