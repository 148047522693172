
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./Pagination.module.css";

const Pagination = ({ pages, currentPage, previousPagePath, nextPagePath }) => {

  const pagination = [];

  if (pages?.length > 1) {
    if (!!previousPagePath) {
      pagination.push(<Link className="" to={previousPagePath} title={"Previous page"} key={'link-prev'}>&lt;</Link>);
    }
    pages.map((p) => {
      if (p.number === currentPage) {
        pagination.push(<b className={`${s.active}`} style={{ cursor: 'auto' }} title={`Current Page`} key={`blog-page-${p.number}`}>{p.number}</b>)
      } else {
        if (p.number == 1 || p.number == pages.length) {
          if (currentPage < pages.length - 2 && p.number == pages.length) {
            pagination.push(<b className=" disabled" title={`...`} key={`blog-page-${p.number}-next`}>...</b>)
          }
          pagination.push(<Link className="" to={p.link} title={p.number} key={`blog-page-${p.number}`}>{p.number}</Link>)
          if (currentPage > 3 && p.number == 1) {
            pagination.push(<b className=" disabled" title={`...`} key={`blog-page-${p.number}-prev`}>...</b>)
          }
        } else {
          if (p.number >= currentPage - 1 && p.number <= currentPage + 1) {
            pagination.push(<Link className=" " to={p.link} title={p.number} key={`blog-page-${p.number}`}>{p.number}</Link>)
          }
        }
      }
    })
    if (!!nextPagePath) {
      pagination.push(<Link className="" to={nextPagePath} title={"Next page"} key={'link-next'}>&gt;</Link>);
    }
  }

  return (
    <>
      <div className={`d-flex justify-content-center mt-5 ${s.pagination}`} style={{ gap: '10px' }}>
        {pagination?.length > 0 && pagination.map((p) => {
          return p;
        })}
      </div>
    </>
  );
};

export default Pagination;
